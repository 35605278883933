<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'company-report-vacations', params: { cid } }"><a>{{ $t('breadcrumb_company_report_vacations') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns">
          <div class="column">
            <sqr-input-week
              class="field"
              label="company_report_vacations_week_from"
              v-model="weekFrom"
            />
          </div>
          <div class="column">
            <sqr-input-week
              class="field"
              label="company_report_vacations_week_until"
              v-model="weekUntil"
            />
          </div>
          <div class="column">
            <sqr-input-week
              class="field"
              label="company_report_vacations_planned_until"
              v-model="rightUntil"
            />
          </div>
          <div class="column">
            <report-buttons @selected="select" />
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>{{ org }}</pre> -->
    <table class="table is-fullwidth is-hoverable" v-if="org">
      <thead>
        <tr>
          <td></td>
          <td
            v-for="mod in summaryModels"
            :key="mod.op"
            class="has-text-centered tooltip"
            :data-tooltip="mod.name"
          >
            <!-- <fa :icon="['fal', mod.icon]" /> -->
            {{ mod.name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in org.content" :key="entry.id">
          <template v-if="entry.type === 'team'">
            <td
              class="has-background-light has-text-weight-semibold"
              :colspan="summaryModels.length + 1"
            >
              {{ entry.name }}
            </td>
          </template>
          <template v-if="entry.type === 'account'">
            <td>
              <router-link
                :to="{
                  name: 'sheets-year',
                  params: { cid, aid: entry.id, year },
                }"
              >
                <account-name :account="entry" reverse />
              </router-link>
            </td>
            <template v-if="loadingByAid[entry.id]">
              <td :colspan="summaryModels.length">
                <sqr-progress />
              </td>
            </template>
            <template v-else>
              <td class="has-text-centered">
                {{
                  reports[entry.id].right && reports[entry.id].right.vacations
                }}
              </td>
              <td class="has-text-centered">
                {{ reports[entry.id].used | opDuration }}
              </td>
              <td class="has-text-centered">
                {{ reports[entry.id].planned | opDuration }}
              </td>
              <td class="has-text-centered">
                {{ reports[entry.id].remaining | opDuration }}
              </td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs } from 'ramda';
import { DateTime } from 'luxon';

import computeReportVacations from 'sqr-wotime-core/reports/vacations';

import company from './company';

import opDuration from '@/filters/opDuration';

import SqrInputWeek from '@/sqrd/SqrInputWeek';
import AccountName from '@/components/AccountName';
import ReportButtons from '@/components/ReportButtons';
import SqrProgress from '@/sqrd/SqrProgress';

import CompanyName from '@/components/CompanyName';

import yearBoundaries from '@/utils/yearBoundaries';

function colPathes(org, postfix, cid, collection, rid) {
  const pairs = org.content
    .filter(item => item.type === 'account')
    .map(({ id }) => [
      `${id}-${postfix}`,
      ['companies', cid, 'accounts', id, collection, rid],
    ]);
  return fromPairs(pairs);
}

export default {
  name: 'CompanyReportVacations',
  mixins: [company],
  filters: { opDuration },
  components: {
    SqrInputWeek,
    CompanyName,
    AccountName,
    ReportButtons,
    SqrProgress,
  },
  computed: {
    ...mapState('org', {
      org: 'record',
      orgLoading: 'loading',
      orgLoadError: 'error',
    }),
    ...mapState('companyReportVacations', [
      'docs',
      'loading',
      'exists',
      'loadError',
    ]),
    summaryModels() {
      return [
        {
          op: 'right',
          name: this.$t('report_vacations_op_right'),
        },
        {
          op: 'used',
          name: this.$t('report_vacations_op_used'),
        },
        {
          op: 'planned',
          name: this.$t('report_vacations_op_planned'),
        },
        {
          op: 'remaining',
          name: this.$t('report_vacations_op_remaining'),
        },
      ];
    },
    accountIds() {
      const content = this.org?.content ?? [];
      return content.filter(i => i.type === 'account')?.map(i => i.id);
    },
    loadingByAid() {
      const ldg = this.loading;
      const pairs = this.accountIds.map(id => [
        id,
        ldg[`${id}-r`] || ldg[`${id}-s`] || ldg[`${id}-e`],
      ]);
      return fromPairs(pairs);
    },
    reports() {
      const query = {
        weekStart: this.weekFrom,
        weekEnd: this.weekUntil,
        rightEnd: this.rightUntil,
      };
      const docs = this.docs;
      const pairs = this.accountIds.map(id => {
        const right = docs[`${id}-r`];
        const sheets = Object.values(docs[`${id}-s`]?.sheetsById || {});
        const events = Object.values(docs[`${id}-e`]?.eventsById || {});
        const vacations = events
          .filter(e => e.status === 'pending' || e.status === 'accepted')
          .filter(e => e.entryModelId === 'vacation');
        const report = computeReportVacations(query, right, sheets, vacations);
        return [id, report];
      });
      return fromPairs(pairs);
    },
    year() {
      return this.weekFrom?.substring(0, 4);
    },
  },
  data() {
    return {
      weekFrom: '',
      weekUntil: '',
      rightUntil: '',

      building: {},
    };
  },
  mounted() {
    this.select(DateTime.local().weekYear);
  },
  methods: {
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('companyReportVacations', ['sub']),
    select(weekYear) {
      const res = yearBoundaries(weekYear);
      this.weekFrom = res.weekFrom;
      this.weekUntil = res.weekUntil;
      this.rightUntil = res.rightUntil;
      this.load();
    },
    async load() {
      try {
        const weekFrom = DateTime.fromISO(this.weekFrom);
        const weekUntil = DateTime.fromISO(this.weekUntil);
        const rightUntil = DateTime.fromISO(this.rightUntil);

        const weekYear = weekFrom.weekYear;
        if (weekYear < 2010) return;
        if (weekYear !== weekUntil.weekYear) return;
        if (weekYear !== rightUntil.weekYear) return;

        const org = await this.orgSub({
          path: ['companies', this.cid, 'orgs'],
          id: 'default',
        });
        const pathes = {
          ...colPathes(org, 'r', this.cid, 'rights', `${weekYear}`),
          ...colPathes(org, 's', this.cid, 'reports', `${weekYear}-sheets`),
          ...colPathes(org, 'e', this.cid, 'reports', `${weekYear}-events`),
        };
        this.sub({ pathes });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
